<template>
  <div>
    <b-overlay :show="show" rounded="sm">
      <b-card no-body class="mb-0 pb-2">
        <div class="m-2" v-if="TokenService.getPermissions('add_topics_review_statuses')">
          <div class="d-flex justify-content-between">
            <div class="flex-grow-1">
              <div class="row">
                <div class="col-md-3">
                  <!-- <b-form-input class="mb-1" v-model="record_filters.name" placeholder="status" type="text" /> -->
                </div>
              </div>
            </div>
            <TopicsReviewStatusAdd
              :manage="TokenService.getPermissions('add_topics_review_statuses')"
              @emitStatusAdded="getTopicsReviewStatuses"
            ></TopicsReviewStatusAdd>
          </div>
        </div>

        <b-table-simple :key="refreshScreen" v-if="get_topics_review_statuses.length > 0" hover caption-top responsive
          class="rounded-bottom mb-0">
          <b-thead head-variant="light">
            <b-tr>
                <b-th></b-th>
                <b-th>Status Name</b-th>
                <b-th>Description</b-th>
                <b-th>Default</b-th>
                <b-th
                  v-if="TokenService.getPermissions('update_topics_review_statuses') || TokenService.getPermissions('delete_topics_review_statuses')"
                >Action</b-th>
            </b-tr>
          </b-thead>
          <draggable
              v-model="get_topics_review_statuses" 
              tag="tbody"
              handle=".handle"
              @end="onDragEnd"
            >
              <tr v-for="item in get_topics_review_statuses" :key="item._id">
                <!-- <td>{{ index + 1 }}</td> -->
                <td>
                  <feather-icon icon="MoveIcon" size="12" class="handle" />
                </td>
                <td>
                    {{ item.name }}
                </td>
                <td>{{ item.description }}</td>
                <b-th>
                    <b-form-checkbox 
                        switch 
                        size="lg"
                        v-model="item.default_status" 
                        @change="updateStatus(item)"
                        :value="1" 
                        :unchecked-value="0"
                        :disabled="!TokenService.getPermissions('update_topics_review_statuses')"
                    ></b-form-checkbox>
                </b-th>
                <td
                    v-if="TokenService.getPermissions('update_topics_review_statuses') || TokenService.getPermissions('delete_topics_review_statuses')"
                  >
                  <div class="d-flex align-items-center">
                    <b-dropdown variant="link" no-caret>
                      <template #button-content>
                        <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                      </template>
                      <b-dropdown-item 
                        v-if="TokenService.getPermissions('update_topics_review_statuses')"
                        @click="editBtn(item)">
                        <div>
                          <feather-icon icon="EditIcon" />
                          <span class="align-middle ml-50">Edit</span>
                        </div>
                      </b-dropdown-item>
                      <b-dropdown-item 
                        v-if="TokenService.getPermissions('delete_topics_review_statuses')"
                        @click="deleteBtn(item._id)">
                        <div>
                          <b-spinner v-if="loading" small class="mr-1" />
                          <feather-icon v-else icon="TrashIcon" />
                          <span class="align-middle ml-50">Delete</span>
                        </div>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </td>
              </tr>
          </draggable>
        </b-table-simple>
        <!-- <div class="mx-2 mt-1">
          <div class="d-flex justify-content-between align-items-center flex-column flex-md-row">
            <div class="dataTables_info mt-1">Showing {{ get_topics_review_statuses.from }} to {{ get_topics_review_statuses.to }} of {{ get_topics_review_statuses.total }}
              entries</div>
            <pagination class="mt-1 mb-0" :data="get_topics_review_statuses" :limit=2 @pagination-change-page="getTopicsReviewStatuses"></pagination>
          </div>
        </div> -->
      </b-card>
    </b-overlay>
    <TopicsReviewStatusEdit
      :manage="isAddNotifications"
      @emitStatusUpdated="getTopicsReviewStatuses"
      :edit_topic_review_status="edit_topic_review_status"
    ></TopicsReviewStatusEdit>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BOverlay,
  BLink,
  BBadge,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTableSimple,
  BThead,
  BFormCheckbox,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BCardHeader,
  BCardBody,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import TopicsReviewStatusAdd from "./TopicsReviewStatusAdd.vue";
import TopicsReviewStatusEdit from "./TopicsReviewStatusEdit.vue";
import AddEditCategory from "../app/list/users-list/AddEditCategory.vue";
import axios from "axios";
import * as _ from "lodash";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Helpers from '../../Helpers';
import TokenService from "../../TokenService";
import draggable from "vuedraggable";
export default {
  components: {
    TopicsReviewStatusAdd,
    TopicsReviewStatusEdit,
    BOverlay,
    AddEditCategory,
    BCard,
    BFormSelect,
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTableSimple,
    BFormCheckbox,
    BThead,
    BTr,
    BTh,
    ToastificationContent,
    BTd,
    BTbody,
    BTfoot,
    BFormInput,
    vSelect,
    TopicsReviewStatusAdd,
    BSpinner,
    draggable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      drag: false,
      classId: "",
      show: false,
      refreshScreen: 0,
      alertData: "",
      errorMsg: "",
      loading: false,
      isAddNotifications: false,
      isViewNotifications: false,

      record_filters: {},
      get_topics_review_statuses: [],

      edit_topic_review_status: {},
      TokenService,
    };
  },
  mounted() {
    this.isAddNotifications = JSON.parse(localStorage.getItem("permission")).permission.some(permission => permission === "add_topics_review_statuses");
    this.isViewNotifications = JSON.parse(localStorage.getItem("permission")).permission.some(permission => permission === "view_topics_review_statuses");
    this.bearerToken = "Bearer " + localStorage.getItem("_t")
  },
  created() {
    if(!TokenService.getPermissions("view_topics_review_statuses")) {
      this.$router.push("/error-404");
    }
    this.getTopicsReviewStatuses();
  },
  methods: {
    setPermission() {
      if (localStorage.getItem("_t")) {
        axios
          .get(process.env.VUE_APP_API_URL + "/tokencheck", {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          })
          .then((get_response) => {
            if (get_response.data.message == "authenticated") {
              var permissiondata = JSON.stringify(get_response.data.permission);
              if (permissiondata) {
                localStorage.setItem("permission", permissiondata);
                this.gettabledata();
              }
            }
          })
          .catch(function (error) {
            if (error.response) {
              if (error.response.data.message == "Unauthenticated.") {
                localStorage.removeItem("permission", { permission: [] });
              }
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("Error", error.message);
            }
          });
      }
    },
    showToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: this.alertData,
          variant,
        },
      });
    },
    async onDragEnd() {
      console.log('onDragEnd get_topics_review_statuses=',this.get_topics_review_statuses);
        const payload = {
          topics_review_statuses: this.get_topics_review_statuses,
        }
        try {
            const res = await axios.put(process.env.VUE_APP_API_URL + "/topics/review/status/order",
                payload,
                {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("_t"),
                    },
                });
            this.getTopicsReviewStatuses();
            this.alertData = res.data.message;
            this.showToast("success");
            this.loading = false;
        } catch (error) {
            console.log('error=',error)
            this.errorMsg = Helpers.catchFunction(error);
            this.loading = false;
            this.alertData = this.errorMsg;
            this.showToast("danger");
            this.getTopicsReviewStatuses();
        }
    },
    async updateStatus(item){
        const payload = {
            default_status: item.default_status,
            _id: item._id
        }
        try {
            const res = await axios.put(process.env.VUE_APP_API_URL + "/topics/review/status/switch",
                payload,
                {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("_t"),
                    },
                });
            this.getTopicsReviewStatuses();
            this.alertData = res.data.message;
            this.showToast("success");
            this.loading = false;
        } catch (error) {
            console.log('error=',error)
            this.errorMsg = Helpers.catchFunction(error);
            this.loading = false;
            this.alertData = this.errorMsg;
            this.showToast("danger");
            this.getTopicsReviewStatuses();
        }
    },
    getTopicsReviewStatuses: function (page = 1) {
      var url = process.env.VUE_APP_API_URL + `/topics/review/statuses?page=${page}`;
      const payload = this.record_filters
      this.show = true;
      axios
        .post(url, payload, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((res) => {
          this.get_topics_review_statuses = res.data.statuses;
          this.show = false;
        })
        .catch(function (error) {
          console.log("error", error);
        });
    },

    editBtn(statusData) {
      this.edit_topic_review_status = statusData;
      this.$bvModal.show("edit-modal");
    },
    deleteBtn(id) {
      console.log('deleteBtn id=',id)
      Swal.fire({
        title: "Are you sure?",
        text: `Do You want to delete this status!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.delete(id)
        }
      });
    },
    async delete(id) {
      this.loading = true;
      try {
        const resp = await axios.delete(`${process.env.VUE_APP_API_URL}/topics/review/status/${id}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        });

        this.alertData = resp.data.message;
        this.showToast("success");
        this.loading = false;
        this.getTopicsReviewStatuses();
      } catch (error) {
        console.log('error=',error)
        this.errorMsg = Helpers.catchFunction(error);
        this.alertData = this.errorMsg;
        this.showToast("danger");
        this.loading = false;
      }
    },
    filterDropdown() {
      this.getTopicsReviewStatuses();
    },
  },
};
</script>
<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>
<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";

  .add-catrgory-button {
    margin-top: 10px;
  }
</style>
<style>
  .vs__open-indicator {
    fill: #9d9d9d !important;
  }
</style>
 