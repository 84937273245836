<template>
    <div>
        <div v-if="lottieJson">
            <Lottie :options="lottieOptions" :style="{
                width: galleryLottie ? '100px' : (forModel ? '200px' : '30px'),
                height: galleryLottie ? '100px' : (forModel ? '200px' : '30px')
            }" :key="keyForLottie">
            </Lottie>
        </div>

        <!-- <div v-else
            Loading animation...
        </div> -->
    </div>
</template>

<script>
import axios from 'axios';
import Lottie from 'vue-lottie';

export default {
    props: {
        url: {
            type: String,
            required: true
        },
        forModel: {
            type: Boolean,
            required: false
        },
        keyForLottie: {
            type: Number,
            required: false
        },
        galleryLottie: {
            type: Boolean,
            required: false
        },
    },
    components: {
        Lottie
    },
    data() {
        return {
            lottieJson: null
        };
    },
    computed: {

        lottieOptions() {
            return {
                loop: true,
                autoplay: true,
                animationData: this.lottieJson
            };
        }
    },
    created() {
        this.getLottieOptions(this.url);
    },
    methods: {
        getLottieOptions(url) {

            axios.get(url)
                .then(response => {
                    this.lottieJson = response.data;
                })
                .catch(error => {
                    console.error('Error fetching Lottie data:', error);
                });
        }
    }
};
</script>

<style>
/* Add any necessary styles here */
</style>