<template>
    <div>
        <b-button variant="primary" v-if="manage" v-b-modal.add-modal>
            <span class="text-nowrap">Add Status</span>
        </b-button>
        <b-modal id="add-modal" hide-footer size="md" centered title="Add Status" @hidden="resetModal">
            <div class="modal_content">
                <b-alert dismissible :show="hasError" class="p-1" variant="danger" @dismissed="clearErrorMsg">{{ errorMsg }}</b-alert>

                <p class="mb-0 ">Status Name</p>
                <b-form-input class="mb-1" v-model="record.name" placeholder="Status name" type="text" />

                <p class="mb-0 ">Status Description</p>
                <b-form-input class="mb-1" v-model="record.description" placeholder="Status description" type="text" />

                <!-- <b-form-checkbox
                    class="mb-1"
                    id="checkbox-1"
                    v-model="record.default_status"
                    name="checkbox-1"
                    value="1"
                    unchecked-value="0"
                    >
                    Default
                </b-form-checkbox> -->

                <div class="modal-footer px-0">
                    <b-button @click="saveBtn" :disabled="loading" variant="primary">
                        <b-spinner v-if="loading" small />
                        Add
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {
    BButton,
    BFormInput,
    BFormGroup,
    BAlert,
    BSpinner,
    BFormCheckbox,
} from "bootstrap-vue";
import axios from "axios";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import * as Helpers from '../../Helpers';
export default {
    components: {
        BButton,
        BFormInput,
        BFormGroup,
        BAlert,
        BSpinner,
        BFormCheckbox,
        ToastificationContent,
        vSelect,
    },
    data() {
        return {
            baseVuePath: "@view/src/views/components/themes/",
            baseReactNativePath: "@src/components/themes/",
            vuePath: "@view/src/views/components/themes/",
            reactNativePath: "@src/components/themes/",
            errorMsg: "",
            loading: false,
            alertMessageToast: "",
            record: {
                // default_status: 0,
            },
        };
    },
    computed: {
        hasError() {
            return this.errorMsg != "";
        },
    },
    watch: {
    },
    methods: {
        showToast(variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Notification",
                    icon: "BellIcon",
                    text: this.alertMessageToast,
                    variant,
                },
            });
        },
        clearErrorMsg() {
            this.errorMsg = "";
        },
        resetModal() {
            this.errorMsg = "";
        },
        async saveBtn() {
            let status = await this.checkValidation();
            if (!status) {
                return;
            }

            this.loading = true;
            try {
                const resp = await axios.post(process.env.VUE_APP_API_URL + "/topics/review/status/add",
                    this.record,
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("_t"),
                        },
                    });
                this.alertMessageToast = resp.data.message;
                this.showToast("success");
                this.$emit('emitStatusAdded');
                this.$bvModal.hide("add-modal");
                this.loading = false;
            } catch (error) {
                this.errorMsg = Helpers.catchFunction(error);
                this.loading = false;
                this.alertMessageToast = this.errorMsg;
                this.showToast("danger");
            }
        },
        checkValidation() {
            if (!this.record.name) {
                this.errorMsg = "Status name field is required."
                return;
            }
            if (!this.record.description) {
                this.errorMsg = "Status description field is required."
                return;
            }
            return "ok";
        },
    },
    props: [
        "manage",
    ],
};
</script>
