const TOPIC_REVIEW_BOARD = "TOPIC_REVIEW_BOARD";
const PERMISSIONS = "permission";
const TIME_GRAPH_SETTING = "TIME_GRAPH_SETTING";
const TIME_GRAPH_SETTING_DATES = "TIME_GRAPH_SETTING_DATES";

export const getTopicReviewBoard = (key = null) => {
    const setting = window.localStorage.getItem(TOPIC_REVIEW_BOARD);
    if (setting) {
        const parsedSetting = JSON.parse(setting);
        return key ? parsedSetting[key] : parsedSetting;
    }

    return null;
};

export const saveTopicReviewBoard = (key, value) => {
    let currentSettings = getTopicReviewBoard();
    
    if (!currentSettings) {
        currentSettings = {};
    }

    currentSettings[key] = value;
    window.localStorage.setItem(TOPIC_REVIEW_BOARD, JSON.stringify(currentSettings));
};

// export const getPermissions = (permissionKey = null) => {
//     const permissions = window.localStorage.getItem(PERMISSIONS);
//     return permissions ? JSON.parse(permissions) : null;
// };
export const getPermissions = (permissionKey = null) => {
    const permissions = window.localStorage.getItem(PERMISSIONS);
    if (permissions) {
        const parsedPermissions = JSON.parse(permissions);
        if (parsedPermissions && Array.isArray(parsedPermissions.permission)) {
            if (permissionKey) {
                return parsedPermissions.permission.includes(permissionKey);
            } else {
                return parsedPermissions.permission;
            }
        }
    }

    return null;
};

export const getTimeGraphSetting = () => {
    const settings = window.localStorage.getItem(TIME_GRAPH_SETTING);
    return settings ? JSON.parse(settings) : null;
};

export const updateTimeGraphSetting = (setting) => {
    window.localStorage.setItem(TIME_GRAPH_SETTING, JSON.stringify(setting));
};

export const getTimeGraphDatesSetting = () => {
    const settings = window.localStorage.getItem(TIME_GRAPH_SETTING_DATES);
    return settings ? JSON.parse(settings) : null;
};

export const updateTimeGraphDatesSetting = (setting) => {
    window.localStorage.setItem(TIME_GRAPH_SETTING_DATES, JSON.stringify(setting));
};

export default {
    getTopicReviewBoard,
    saveTopicReviewBoard,
    getPermissions,
    getTimeGraphSetting,
    updateTimeGraphSetting,
    getTimeGraphDatesSetting,
    updateTimeGraphDatesSetting,
};
